<template>
  <div class="pagination">
    <a-pagination
      size="small"
      :total="total"
      show-size-changer
      show-quick-jumper
      :pageSizeOptions="['10','20', '50', '100']"
      :current="page"
      :pageSize.sync="pageSize"
      @change="$listeners.pageChange"
      @showSizeChange="$listeners.pageSizeChange"
    />
    <a-icon
      type="sync"
      style="
        margin-left: 10px;
        margin-left: 10px;
        display: flex;
        align-items: center;
      "
      @click="$listeners.refresh"
    />
  </div>
</template>

<script>
export default {
    props:{
        total:{
            type:Number,
            default:0
        },
        page:{
            type:Number,
            default:1
        }
    },
    data(){
      return{
        pageSize:20
      }
    },
    methods:{
        // pageChange(page,pageSize){
        //     this.$emit('pageChange',{page,pageSize})
        //     console.log(this.$listeners)
        // },
        // pageSizeChange(page,pageSize){
        //     this.$emit('pageSizeChange',{page,pageSize})
        // },
        // refresh(){
        //     this.$emit('refresh')
        // }

    }
};
</script>

<style scoped>
.pagination {
  display: flex;
  align-items:center
}
</style>